import { api } from "../core/api";

const getProjects = async (developerId) => {
  const response = await api.get(`/api/project/all/user/${developerId}`);
  return response.data;
};

const createProject = async (data) => {
  const response = await api.post(`/api/project/store/`, data);
  return response.data;
};

const updateProject = async (projectId, data) => {
  const response = await api.post(`/api/project/update/${projectId}`, data);
  return response.data;
};

const getProjectInformation = async (projectId) => {
  const response = await api.get(`/api/project/edit/${projectId}`);
  return response.data;
};

const getPersonType = async () => {
  const response = await api.get(`/api/person-type`);
  return response.data;
};

const getTerrainType = async () => {
  const response = await api.get(`api/project/type/get/All/terrain`);
  return response.data;
};

const getRecreationEquipments = async () => {
  const response = await api.get(`api/project/recreation-area/equipments/all`);
  return response.data;
};

const getDocumentTypes = async () => {
  const response = await api.get("/api/document-type");
  return response.data.documentTypes;
};

export {
  getDocumentTypes,
  getProjects,
  createProject,
  updateProject,
  getProjectInformation,
  getPersonType,
  getTerrainType,
  getRecreationEquipments,
};
