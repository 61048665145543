<template>
  <div id="app">
    <data-table v-bind="parametersTable1" @actionTriggered="handleAction" />
  </div>
</template>

<style lang="css">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  main {
    padding: 32px;
  }
  table thead tr th, table tbody tr td {
    text-align: left;
  }
</style>

<script>
import {getProjects} from '@/services/projectAPI';
import {getDeveloperData} from '@/services/developerData';

export default {
  name: "App",
  data(){
    return {
      developerId: null,
      projects: [],
    }
  },
  mounted() {
    this.getProjectsAll();
  },
  computed: {
    parametersTable1() {
      return {
        lang: "es",
        actions: ["view"],
        actionMode: "single",
        data: this.projects,
        columns: [
          {
            key: "id",
            title: "No.",
          },
          {
            key: "name",
            title: "Proyecto",
          },
        ],
      };
    },
  },

  methods: {
    handleAction(actionName, data) {
      if (actionName == "view") {
        this.$router.push({
          name: "ProjectInformation",
          params: { projectId: data.id },
        });
      }
    },
    getProjectsAll(){
      getDeveloperData().then((data)=>{
        getProjects(data.id).then((projects)=>{
          this.projects = projects;
        });
      });
    }

  },
};
</script>
