<template>
  <div class="dashboard">

    <div class="page-title-wrap">
      <h1>Dashboard</h1>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3 ml-1">
              <b-card-text>
                <p class="text-start" style="height: 35px">
                  Cupo de proyectos habilitados
                </p>
                <hr class="mb-0" />
                <p class="value-counter">0</p>
              </b-card-text>
            </b-card>
          </div>
          
          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3">
              <b-card-text>
                <p class="text-start" style="height: 35px">
                  Preseleccionados enviados a la banca
                </p>
                <hr class="mb-0" />
                <p class="value-counter">0</p>
              </b-card-text>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3">
              <b-card-text>
                <p class="text-start" style="height: 35px">
                  Evaluados por la banca
                </p>
                <hr class="mb-0" />
                <p class="value-counter">0</p>
              </b-card-text>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3 bg-primary text-white">
              <b-card-text class="main-navigation-button" style="height: 141px">
                <div style="padding-top: 6vh">DASHBOARD POWER BI</div>
              </b-card-text>
            </b-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3">
              <b-card-text>
                <p class="text-start" style="height: 35px">
                  Precalificados por la banca
                </p>
                <hr class="mb-0" />
                <p class="value-counter">0</p>
              </b-card-text>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3">
              <b-card-text>
                <p class="text-start" style="height: 35px">
                  En análisis por la banca
                </p>
                <hr class="mb-0" />
                <p class="value-counter">0</p>
              </b-card-text>
            </b-card>
          </div>

          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3">
              <b-card-text>
                <p class="text-start" style="height: 35px">
                  Rechazados por la banca
                </p>
                <hr class="mb-0" />
                <p class="value-counter">0</p>
              </b-card-text>
            </b-card>
          </div>
          
          <div class="col-md-3">
            <b-card tag="article" class="mb-2 mt-3">
              <b-card-text>
                <p class="text-start" style="height: 35px">Vinculados</p>
                <hr class="mb-0" />
                <p class="value-counter">0</p>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <b-card
          tag="article"
          style="height: 93.5%;"
          class="mb-2 mt-3"
        >
          <b-card-text>
            <p class="text-start">Gráfica</p>
            <hr class="mb-0" />
            <Bar
              :chart-options="chartOptions"
              :chart-Id="BAR"
              :chart-data="chartData"
              :height="'270px'"
            />
          </b-card-text>
        </b-card>
      </div>
    </div>
    <div class="content-wrap-container">
      <projects-table />
    </div>
  </div>
</template>
<script>
import ProjectsTable from "@/components/Project/ProjectsTable.vue";
import store from "../../store/index";
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  components: { ProjectsTable, Bar },
  name: "Home",
  data() {
    return {
      chartData: {
        labels: [
          "INICIADOS",
          "EN EVALUACIÓN",
          "ELEGIBLES",
          "EN ESPERA",
          "PRE SELECCIONADOS",
          "BENEFICIADOS",
          "NO ACEPTARON",
        ],
        datasets: [{ label: "Cantidades", data: [40, 20, 12] }],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  computed: {
    user() {
      return store.getters.getUser;
    },
  },
  async mounted() {},
};
</script>




<style>
.card-body{
  min-height: 198px;
}

.dashboard .card-body p{
  font-family:"Segoe-UI-Regular";
  font-size: 17px; 
}

.card-body .value-counter{
  margin-bottom: 0px;
  font-family: "Segoe-UI-SemiBold"!important;
  font-size: 50px!important;
  color:#474747;
}

</style>
