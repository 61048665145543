import { api } from "../core/api";

const getDeveloperData = async () => {
  const response = await api.get("/api/user/developer-data");
  return response.data;
};

const getPersonTypes = async () => {
  const response = await api.get("/api/person-type");
  return response.data.personTypes;
};

const getDocumentTypes = async () => {
  const response = await api.get("/api/document-type");
  return response.data.documentTypes;
};

const saveDeveloperData = async (developerData) => {
  const response = await api.post(`/api/user/update-profile/${developerData.id}`, developerData);
  return response.data;
};

export { getDeveloperData, getPersonTypes, saveDeveloperData, getDocumentTypes };
